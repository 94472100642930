import HomePage from "./components/HomePage.vue";
import SignUp from "./components/SignUp.vue";
import LoginPage from "./views/login/LoginPage.vue";
import PaymentsPage from "./components/PaymentsPage.vue";
import ContributionsPage from "./components/ContributionsPage.vue";
import ArgosyPage from "./components/ArgosyPage.vue";
import WebsitePage from "./components/WebsitePage.vue";
import WebsiteComingSoon from "./views/website/comingsoon/WebsiteComingSoon.vue";
import Website from "./views/website/official/OfficialWebsiteMainPage.vue";
import SignUpPage from "./views/signup/SignUpPage.vue";

import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        name: 'WebsiteComingSoon',
        component: WebsiteComingSoon,
        path: '/'
    },{
        name: 'Website',
        component: Website,
        path: '/official-site'
    },
    {
        name: 'WebsitePage',
        component: WebsitePage,
        path: '/website'
    },
    {
        name: 'HomePage',
        component: HomePage,
        path: '/home'
    },
    {
        name: 'SignUp',
        component: SignUp,
        path: '/sign-up'
    },
    {
        name: 'SignUpPage',
        component: SignUpPage,
        path: '/signup'
    },
    {
        name: 'Login',
        component: LoginPage,
        path: '/login'
    },
    {
        name: 'Contributions',
        component: ContributionsPage,
        path: '/contributions'
    },
    {
        name: 'Payments',
        component: PaymentsPage,
        path: '/payments'
    },
    {
        name: 'Argosy',
        component: ArgosyPage,
        path: '/argosy'
    }
]


const router = createRouter({
    history: createWebHistory(),
    routes
})


export default router;