<template>
  <div class="container mt-2">
    <div class="row centered g-0">
        <div class="col side-intro">
            <div class="row centered">
                <img class="logo"  src="../../assets/heyguys-high-resolution-logo-transparent.png"/>
            </div>
            <div class="row">
                <h3 class="punch-line">Manage your squad's finance efficiently & transaprently</h3>
                <p>Sign up for free and enjoy access to all features. No credit card required.</p>
            </div>
        </div>

        <div class="col banana">
            <br/>

            <div class="row account">
                <h1>Sign up now</h1>
                <p class="greyed">Create a free account</p>
                <div class="singup-with-google"></div>
            </div>
            <div class="row signup-form">
                <!-- email  -->
                <div id="sign-up-names">
                    <div class="row">
                        <label>Name(s)</label>
                    </div>
                    <div class="row">
                        <input type="text"  v-model="name" placeholder="" />
                    </div>
                </div>
                <!--  -->
                <div id="sign-up-email">
                    <div class="row">
                        <label>Email</label>
                    </div>
                    <div class="row">
                        <input type="text"  v-model="email" placeholder="" />
                    </div>
                </div>
                <!--  -->
                <div id="sign-up-alias">
                    <div class="row">
                        <label>Alias</label>
                    </div>
                    <div class="row">
                        <input type="text"  v-model="email" placeholder="" />
                    </div>
                </div>
                <!--  -->
                <div id="sign-up-msisdn">
                    <div class="row">
                        <label>Phone Number</label>
                    </div>
                    <div class="row">
                        <input type="text"  v-model="email" placeholder="" />
                    </div>
                </div>
                <div id="sign-up-password">
                    <div class="row">
                        <label> Password</label>
                    </div>
                    <div class="row">
                        <input type="text"  v-model="password" placeholder="" />
                    </div>
                </div>

                <div class="row submit">
                    <div class="col">
                        <button type="button" v-on:click="registerUser" >Sign Up</button>
                    </div>
                </div>
            </div>
            
            <div class="row suggest-signin">
                <p>Already have an account? <a href="/login" target="_blank" rel="noopener noreferrer">Sign in</a> </p>
            </div>
        </div>

        <div class="row bananas">
            <br/>

            <div class="row account">
                <h1>Sign up now</h1>
                <p class="greyed">Create a free account</p>
                <div class="singup-with-google"></div>
            </div>
            <div class="row signup-form">
                <!-- email  -->
                <div id="sign-up-names">
                    <div class="row">
                        <label>Name(s)</label>
                    </div>
                    <div class="row">
                        <input type="text"  v-model="name" placeholder="" />
                    </div>
                </div>
                <!--  -->
                <div id="sign-up-email">
                    <div class="row">
                        <label>Email</label>
                    </div>
                    <div class="row">
                        <input type="text"  v-model="email" placeholder="" />
                    </div>
                </div>
                <!--  -->
                <div id="sign-up-alias">
                    <div class="row">
                        <label>Alias</label>
                    </div>
                    <div class="row">
                        <input type="text"  v-model="email" placeholder="" />
                    </div>
                </div>
                <!--  -->
                <div id="sign-up-msisdn">
                    <div class="row">
                        <label>Phone Number</label>
                    </div>
                    <div class="row">
                        <input type="text"  v-model="email" placeholder="" />
                    </div>
                </div>
                <div id="sign-up-password">
                    <div class="row">
                        <label> Password</label>
                    </div>
                    <div class="row">
                        <input type="text"  v-model="password" placeholder="" />
                    </div>
                </div>

                <div class="row submit">
                    <div class="col">
                        <button type="button" v-on:click="registerUser" >Sign Up</button>
                    </div>
                </div>
            </div>
            
            <div class="row suggest-signin">
                <p>Already have an account? <a href="/login" target="_blank" rel="noopener noreferrer">Sign in</a> </p>
            </div>
        </div>
        
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
@import "./signup.css";
</style>